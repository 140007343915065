<template>
  <div class="layout-spacing">
    <div class="widget widget-activity-four">
      <div class="widget-heading">
        <h5 class="">
          Epidémiologie Stats
        </h5>
        <div class="stat_total">
          Total: {{ analyses.length }}
        </div>
      </div>
      <div class="widget-content">
        <type-analyse
          v-for="(t,i) in sortTypes"
          :key="i"
          :type="t"
          :color="colors[i]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import typeAnalyse from './typAnalyse.vue'
export default {
    components: {typeAnalyse},
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            types: 'sanitaire/typeAnalyses',
            analyses: 'sanitaire/analyses'
        }),
        sortTypes(){
            return [...this.types].sort((b,a) => {
                return [...this.analyses].filter(item => item.type === a.uid).length - [...this.analyses].filter(item => item.type === b.uid).length
            })
        },
        colors(){
            return ['primary', 'danger', 'success', 'warning','secondary', 'info', 'dark']
        }
    }

}
</script>

<style lang="scss" scoped>
.stat_total{
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 16px;
    font-weight: bold;
    color: rgba($color: #000000, $alpha: 0.6)
}
</style>