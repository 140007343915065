import { render, staticRenderFns } from "./consultation.vue?vue&type=template&id=41a86a27&scoped=true"
import script from "./consultation.vue?vue&type=script&lang=js"
export * from "./consultation.vue?vue&type=script&lang=js"
import style0 from "./consultation.vue?vue&type=style&index=0&id=41a86a27&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41a86a27",
  null
  
)

export default component.exports