<template>
  <div class="customer-issues">
    <div class="customer-issue-list">
      <div class="customer-issue-details">
        <div class="customer-issues-info">
          <h6
            class="mb-2 font-14"
            :class="'text-'+color"
          >
            {{ medicament.libelle }} ({{ pourcentage }}%)
          </h6>
          <p
            class="issues-count mb-2 font-14"
            :class="'text-'+color"
          >
            {{ usage }}/{{ traitements.length }}
          </p>
        </div>
        <div class="customer-issues-stats">
          <div class="progress progress-md">
            <div
              class="progress-bar position-relative"
              :class="'bg-gradient-'+color"
              role="progressbar"
              :style="progressStyle"
              :aria-valuenow="pourcentage"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span class="success-teal" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        medicament: {type: Object, required: true},
        color: {type: String, default: 'primary'}
    },
    data(){
        return {
            
        }
    },
    methods: {
        getMedicamentUsage(medicament){
            return [...this.traitements].filter(item => {
                    let meds = JSON.parse(item.medicaments)
                    let m = meds.find(i => i.medoc === medicament.uid)
                    return m
            }).length
        }
    },
    computed: {
        ...mapGetters({
            traitements: 'sanitaire/traitements'
        }),
        usage(){
            return this.getMedicamentUsage(this.medicament)
        },
        pourcentage(){
            if(this.usage > 0) return (this.usage*100/this.traitements.length).toFixed(2)
            return 0
        },
        progressStyle(){
            return  {
                "width": this.pourcentage+'%'
            }
        }
    }
}
</script>

<style>

</style>