<template>
  <div class="widtget d-flex widget-six mb-2">
    <div class="widget-six-icon-container background-third center">
      <i
        class="las text-white font-25"
        :class="icon"
      />
    </div>
    <div class="p-3 flex-grow-1">
      <span class="m-2 strong text-third">{{ espece.libelle }}</span>
      <span class="float-right pt-1 font-12">{{ filtredAnimaux.length }}</span>
      <p class="ml-2 mt-2 mb-0 font-12 strong">
        Total {{ filtredAnimaux.length }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        espece: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            animaux: 'identification/animaux'
        }),
        icon(){
            if(this.espece.code === 'chien') return 'la-dog'
            if(this.espece.code === 'chat') return 'la-cat'
            if(this.espece.code === 'perroquet') return 'la-crow'
            return 'la-animal'
        },
        filtredAnimaux(){
            return [...this.animaux].filter(item => item.espece === this.espece.uid)
        }
    }

}
</script>

<style>

</style>