<template>
  <div class="layout-spacing">
    <router-link
      :to="{name: 'animaux'}"
      class="widget quick-category"
    >
      <div class="quick-category-head">
        <span class="quick-category-icon qc-danger rounded-circle">
          <i class="las la-dog" />
        </span>
        <div class="ml-auto">
          
        </div>
      </div>
      <div class="quick-category-content">
        <h3>{{ animaux.length }}</h3>
        <p class="font-17 text-third mb-0">
          Animaux
        </p>
        <div class="chart">
          <apexchart
            type="pie"
            width="210"
            :options="chartOptions"
            :series="chartData"
          />
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import apexchart from 'vue-apexcharts'
export default {
    components: {apexchart},
    data(){
        return {
            chartEspeces:[],
            chartData: []
        }
    },
    watch: {
        updateChart:{
            handler(){ this.initChart()},
            deep: true
        }
    },
    mounted(){
        this.initChart()
    },
    methods: {
        initChart(){
            this.chartData = []
            this.chartEspeces = []
            this.especes.forEach(item => {
                this.chartEspeces.push(item.libelle)
                this.chartData.push([...this.animaux].filter(i => i.espece === item.uid).length)
            })
        }
    },
    computed: {
        ...mapGetters({
            animaux: 'identification/animaux',
            especes: 'identification/especes'
        }),
        updateChart(){
            return {a: this.animaux, e: this.especes}
        },
        chartOptions(){
            return {
                chart: {
                    width: 250,
                    type: 'pie',
                },
                legend: {
                    enabled: false,
                    show: false
                },
                labels: this.chartEspeces,
                colors: ['#1C6100', '#b50404', '#007BFF', '#87c7a0'],
                responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                    width: 200
                    },
                    legend: {
                        show: false,
                        position: 'bottom'
                    }
                }
                }]
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.quick-category-content{
    position: relative;
    .chart{
        position: absolute;
        top: -90px;
        left: 15%;
        min-height: 300px;
    }
}


</style>