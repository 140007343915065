<template>
  <div class="layout-spacing">
    <div class="widget">
      <div class="widget-heading">
        <h5 class="">
          Médicaments les plus utilisés
        </h5>
        <span> Total: {{ traitements.length }}</span>
      </div>
      <div class="widget-content">
        <medicament
          v-for="(m, i) in sortedMedicaments"
          :medicament="m"
          :key="i"
          :color="colors[i]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Medicament from './medicament.vue'
import { DASHBOARD_BEST_MEDICAMENT } from '../../constants/app';
export default {
    components:{ Medicament},
    data(){
        return {

        }
    },
    methods: {
        getMedicamentUsage(medicament){
            return [...this.traitements].filter(item => {
                    let meds = JSON.parse(item.medicaments)
                    let m = meds.find(i => i.medoc === medicament.uid)
                    return m
            }).length
        }
    },
    computed: {
        ...mapGetters({
            medicaments: 'sanitaire/medicaments',
            traitements: 'sanitaire/traitements'
        }),
        sortedMedicaments(){
            var items =  [...this.medicaments].sort((b,a) => this.getMedicamentUsage(a) - this.getMedicamentUsage(b))
            return items.slice(0, DASHBOARD_BEST_MEDICAMENT)
        },
        colors(){
            return ['secondary', 'danger', 'success', 'warning','primary', 'info', 'dark']
        }
    }

}
</script>

<style lang="scss" scoped>
.widget-heading{
    span{
        position: absolute;
        top: 20px;
        right: 20px;
        font-weight: bold;
        font-size: 14px;
        color: rgba($color: #000000, $alpha: 0.6)
    }
}
</style>