<template>
  <div class="layout-spacing">
    <div class="widget widget-table-one">
      <div class="widget-heading">
        <h5 class="">
          Top {{ topNbClient }} clients
        </h5> <span style="font-size: 12px; margin-left: 10px;">[{{ filterLibelle }}]</span>
        <ul class="tabs tab-pills filter">
          <li>
            <div class="dropdown custom-dropdown-icon">
              <a
                class="dropdown-toggle"
                href="#"
                role="button"
                id="customDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              ><span>Options</span> <i class="las la-angle-down" /></a>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="customDropdown"
              >
                <a
                  @click.prevent="filter = 'nb_animaux'"
                  class="dropdown-item"
                  data-value="Settings"
                  href="javascript:void(0);"
                >Nombre d'animaux</a>
                <a
                  @click.prevent="filter = 'nb_consultation'"
                  class="dropdown-item"
                  data-value="Settings"
                  href="javascript:void(0);"
                >Consultations</a>
                <a
                  @click.prevent="filter = 'nb_analyse'"
                  class="dropdown-item"
                  data-value="Mail"
                  href="javascript:void(0);"
                >Analyses</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
        
      <div class="widget-content">
        <one-client
          v-for="(u, i) in filtredClients"
          :key="i"
          :client="u"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OneClient from './client.vue'
import { DASHBOARD_TOP_CLIENT } from '../../constants/app';
export default {
    components: {OneClient},
    data(){
        return {
            filter: 'nb_animaux'
        }
    },
    computed: {
        ...mapGetters({
            clients: 'auth/proprietaires',
            animaux: 'identification/animaux',
            consultations: 'sanitaire/anamneses',
            analyses: 'sanitaire/analyses'
        }),
        filtredClients(){
            console.log(this.filter)
            switch(this.filter){
              case 'nb_animaux' : {
                return [...this.clients].sort((b,a) => {
                  let anA = [...this.animaux].filter(item => item.proprio === a.uid).length
                  let anB = [...this.animaux].filter(item => item.proprio === b.uid).length 
                  return anA - anB
                }).slice(0,DASHBOARD_TOP_CLIENT)
              }
              case 'nb_consultation': {
                return [...this.clients].sort((b,a) => {
                  let nbA = 0
                  let nbB = 0
                  let anA = [...this.animaux].filter(item => item.proprio === a.uid)
                  let anB = [...this.animaux].filter(item => item.proprio === b.uid)
                  anA.forEach(el => {
                    let nbc = [...this.consultations].filter(c => c.animal === el.uid).length
                    nbA += nbc
                  })
                  anB.forEach(el => {
                    let nbc = [...this.consultations].filter(c => c.animal === el.uid).length
                    nbB += nbc
                  })
                  return nbA - nbB
                }).slice(0,DASHBOARD_TOP_CLIENT)
              }
              case 'nb_analyse': {
                return [...this.clients].sort((b,a) => {
                  let nbA = 0
                  let nbB = 0
                  let anA = [...this.animaux].filter(item => item.proprio === a.uid)
                  let anB = [...this.animaux].filter(item => item.proprio === b.uid)
                  anA.forEach(el => {
                    let nbc = [...this.analyses].filter(c => c.animal === el.uid).length
                    nbA += nbc
                  })
                  anB.forEach(el => {
                    let nbc = [...this.analyses].filter(c => c.animal === el.uid).length
                    nbB += nbc
                  })
                  return nbA - nbB
                }).slice(0,DASHBOARD_TOP_CLIENT)
              }
              default: return [...this.clients].slice(0,DASHBOARD_TOP_CLIENT)
            }
            
        },
        filterLibelle(){
            if(this.filter === 'nb_animaux') return 'Nombre d\'animaux'
            if(this.filter === 'nb_consultation') return 'Nombre de consultations'
            if(this.filter === 'nb_analyse') return 'Nombre d\'analyses'
            return 'Nombre d\'animaux'
        },
        topNbClient(){
          return DASHBOARD_TOP_CLIENT
        }
    }
}
</script>

<style lang="scss" scoped>
.filter{
    position: absolute;
    right: 10px;
}
</style>